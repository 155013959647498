@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #5d5959;
  text-decoration: dotted;
}

.ant-btn-primary {
  background: #c12424 !important;
  color: white;
}

.border-none {
  border: 0 !important;
}

:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-selected::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-active {
  border-bottom-width: 0;
}

:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-active::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-active::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-open::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-open::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-open::after,
:where(.css-dev-only-do-not-override-1l8tm5b).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-open::after {
  border-bottom-width: 0px;
  border-bottom-color: 0;
  border-bottom: 0;
}

.ant-layout-sider-children {
  width: 200px;
}

.css-dev-only-do-not-override-2ncrtl.ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after,
.css-dev-only-do-not-override-2ncrtl.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-selected::after,
.css-dev-only-do-not-override-2ncrtl.ant-menu-light.ant-menu-horizontal
  > .ant-menu-submenu-selected::after,
.css-dev-only-do-not-override-2ncrtl.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-selected::after {
  border-bottom-width: 0;
  border-bottom-color: "";
}
